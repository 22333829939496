<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <div class="content-wrapper py-5">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6 wow fadeInDown">
                            <h5>Pengaturan</h5>
                        </div>
                        <div class="col-sm-6 wow fadeInDown">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item"><a href="/Program">Program</a></li>
                                <li class="breadcrumb-item active">Pengaturan Program</li>
                            </ol>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12 wow fadeInDown">
                            <div class="card cardputih2">
                                <div class="card-header border-transparent">
                                    <h3 class="card-title">Form Input Program</h3>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-3">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <label>Nama Program <small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="nama"
                                                    name="nama" placeholder="Nama Program" v-model="nama">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Tahun <small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="tahun"
                                                    name="tahun" placeholder="Tahun" v-model="tahun">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Anggaran<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <v-select :options="anggaran" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedanggaran" @update:modelValue="onanggaranChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>UPTD <small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <v-select multiple :options="uptd" :reduce="(label) => label.code"
                                                label="label" @option:selected="onuptdChangeSelect($event)"
                                                @option:deselected="onuptdChangeDeselect($event)"
                                                v-model="arraynamauptd"
                                                style="background-color: #ffffff;padding-bottom: 10px;"></v-select>
                                        </div>
                                        <div class="col-sm-12">&nbsp;</div>
                                    </div>
                                </div>
                                <div class="card-footer p-3">
                                    <button type="button" class="btn btn-sm btn-success3 float-left text-white lebar2"
                                        @click="simpan()" v-if="(secretencData == 'Tambah')" data-toggle="tooltip"
                                        data-placement="left" title="Simpan data.."><i class="fas fa-plus-square"></i>
                                        Simpan</button>
                                    <button type="button" class="btn btn-sm btn-success3 float-left text-white lebar2"
                                        v-if="(secretencData != 'Tambah')" @click="update()" data-toggle="tooltip"
                                        data-placement="left" title="Update data.."><i class="fa fa-check-circle"></i>
                                        Update</button>
                                    <button type="button" class="btn btn-sm btn-danger3 float-left text-white lebar2"
                                        @click="batalproses()" data-toggle="tooltip" data-placement="left"
                                        title="Batalkan proses.."><i class="fas fa-window-close"></i> Batal</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import { quillEditor } from 'vue3-quill';
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Footer, NavBar, Menu, vSelect, swal, quillEditor
    },
    data() {
        return {
            halamanloading: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
            nama: '',
            tahun: '',
            uptd: [],
            datauptd: [],
            defaultSelecteduptd: {
                code: 0,
                label: 'Pilih Data',
                tag: '',
            },
            selecteduptd: '',
            paramselecteduptd: [],
            arraynamauptd: [],
            anggaran: [],
            dataanggaran: [],
            defaultSelectedanggaran: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: '',
            },
            selectedanggaran: '',
            validasi: false,
        }
    },
    methods: {
        onanggaranChange(a) {
            try {
                this.selectedanggaran = this.anggaran[a].tag;
            }
            catch (err) {
                return;
            }
        },
        initialize() {
            //Initialize Select2 Elements
            $(".select2").select2();

            //Initialize Select2 Elements
            $(".select2bs4").select2({
                theme: "bootstrap4",
            });
            $('[data-mask]').inputmask();
            //$('#summernote').summernote();
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
            this.halamanloading = false;
        },
        ontabelChange(a) {
            try {
                this.selectedtabel = this.tabel[a].tag;
            }
            catch (err) {
                return;
            }
        },
        batalproses() {
            window.location.replace("/Program");
        },
        uploadfile(idnya, tipenya) {
            var elmnt = document.getElementById("fileToUpload" + idnya);
            try {
                var namanya = elmnt.files[0].name;
                //alert(elmnt.files[0]['type'].toUpperCase());
                if (tipenya == 'image/*') {
                    if ((elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPEG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/PNG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/GIF')) {
                        document.getElementById('suksesnya' + idnya).innerHTML = '*file harus format gambar';
                        return;
                    }
                }
                else {
                    if (elmnt.files[0]['type'] != tipenya) {
                        document.getElementById('suksesnya' + idnya).innerHTML = '*file harus format pdf';
                        return;
                    }
                }
            } catch (error) {
                document.getElementById('suksesnya' + idnya).innerHTML = '*pilih file terlebih dahulu';
                return;
            }
            var fd = new FormData();
            fd.append("fileToUpload", elmnt.files[0], elmnt.files[0].name);
            fd.append("folderToUpload", idnya);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation-FilesUpload", fd, {
                onUploadProgress: function (uploadEvent) {
                    document.getElementById('persennya' + idnya).style.width = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                    document.getElementById('tulisanpersen' + idnya).innerHTML = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                }
            }).then(
                function (res) {
                    document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                    if (res.data.filename != undefined) {
                        document.getElementById(idnya).value = res.data.filename;
                    }
                }
            ).catch(function (e) {
                document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                document.getElementById(idnya).value = '';
                console.log(e);
            }
            )
        },
        clearfilepeta(idnya) {
            if (document.getElementById(idnya).value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'File Tidak Tersedia',
                });
                return false;
            }

            var fd = new FormData();
            fd.append("folderToUpload", idnya);
            fd.append("filenya", document.getElementById(idnya).value);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation-FilesDeleted", fd).then(
                Response => {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: Response.data.message,
                    });
                    document.getElementById(idnya).value == '';
                    this.filenya_slider = '';
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )

            document.getElementById('fileToUpload' + idnya).value = '';
            document.getElementById(idnya).value = '';
            document.getElementById('persennya' + idnya).style.width = "0%";
            document.getElementById('tulisanpersen' + idnya).innerHTML = "0%";
            document.getElementById('suksesnya' + idnya).innerHTML = '';
            return false;
        },
        async ambilreff() {
            this.halamanloading = true;
            var mainAPI2 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI2.get("parastapainnovation_reff-UPTDPengguna?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datauptd = [];
                        this.uptd = [];
                        this.datauptd = Response.data.content.data;
                        this.datauptd.forEach((item) => {
                            this.uptd.push({
                                label: item.nama,
                                code: this.datauptd.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                    }
                    else {
                        this.datauptd = [];
                        this.uptd = [];
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                this.datauptd = [];
                this.uptd = [];
                this.halamanloading = false;
            });

            await mainAPI2.get("parastapainnovation_reff-Anggaran?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.dataanggaran = [];
                        this.anggaran = [];
                        this.dataanggaran = Response.data.content.data;
                        this.dataanggaran.forEach((item) => {
                            this.anggaran.push({
                                label: item.nama,
                                code: this.dataanggaran.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.anggaran.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        onuptdChangeSelect(events) {
            this.paramselecteduptd = [];
            for (let i = 0; i < events.length; i++) {
                this.paramselecteduptd.push(parseInt(events[i].tag));
            }
            //alert(JSON.stringify(this.paramselecteduptd));
        },
        onuptdChangeDeselect(events) {
            //alert(JSON.stringify(events));
            //this.paramselecteduptd = [];
            var temp = [];
            temp.push(events);
            //alert(JSON.stringify(temp));
            var yghilang = '';
            for (let i = 0; i < temp.length; i++) {
                yghilang = parseInt(temp[i].tag);
            }
            //alert(yghilang);
            //alert(this.paramselecteduptd);
            var temparray = [];
            for (let i = 0; i < this.paramselecteduptd.length; i++) {
                //alert(this.paramselecteduptd[i]);
                if (this.paramselecteduptd[i] !== yghilang) {
                    temparray.push(parseInt(this.paramselecteduptd[i]));
                }
            }
            this.paramselecteduptd = temparray;
            //alert(JSON.stringify(this.paramselecteduptd))
        },
        async AmbilDataSingel() {
            this.halamanloading = true;
            this.halamanloading = true;
            var mainAPI2 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI2.get("parastapainnovation_reff-UPTDPengguna?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datauptd = [];
                        this.uptd = [];
                        this.datauptd = Response.data.content.data;
                        this.datauptd.forEach((item) => {
                            this.uptd.push({
                                label: item.nama,
                                code: this.datauptd.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                    }
                    else {
                        this.datauptd = [];
                        this.uptd = [];
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                this.datauptd = [];
                this.uptd = [];
                this.halamanloading = false;
            });

            await mainAPI2.get("parastapainnovation_reff-Anggaran?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.dataanggaran = [];
                        this.anggaran = [];
                        this.dataanggaran = Response.data.content.data;
                        this.dataanggaran.forEach((item) => {
                            this.anggaran.push({
                                label: item.nama,
                                code: this.dataanggaran.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.anggaran.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            });
            var mainAPIDataSingel = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIDataSingel.get("parastapainnovation_program-GetProgramByID?id=" + this.secretencData + "&random=" + random).then(
                Response => {
                    this.nama = Response.data.content.data[0].nama;
                    this.tahun = Response.data.content.data[0].tahun;
                    this.selectedanggaran = Response.data.content.data[0].anggaran;
                    this.defaultSelectedanggaran = {
                        code: Response.data.content.data[0].anggaran,
                        label: Response.data.content.data[0].namaanggaran,
                        tag: Response.data.content.data[0].anggaran,
                    };
                    this.paramselecteduptd = [];
                    var uptd = Response.data.content.data[0].uptd.split(",")
                    var namauptd = Response.data.content.data[0].namauptd.split(",")
                    this.paramselecteduptd = [];
                    //this.arraynamauptd = ['aaa','bbb','ccc'];
                    for (let i = 0; i < uptd.length; i++) {
                        this.paramselecteduptd.push(uptd[i]);
                    }
                    this.arraynamauptd = [];
                    for (let i = 0; i < namauptd.length; i++) {
                        //alert(namauptd[i]);
                        this.uptd.forEach((item) => {
                            if (namauptd[i] == item.label) {
                                this.arraynamauptd.push({
                                    label: item.label,
                                    code: item.code,
                                    tag: item.tag,
                                });
                            }
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        validasidata() {
            if (document.getElementById('nama').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('nama').focus();
                return false;
            }
            if (this.tahun == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Tahun',
                });
                return false;
            }
            if ((this.selectedanggaran == '') || (this.selectedanggaran == null) || (this.selectedanggaran == undefined)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Anggaran',
                });
                return false;
            }
            if (this.paramselecteduptd.length == 0) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih UPTD',
                });
                return false;
            }
            this.validasi = true;
            return;
        },
        simpan() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }
            var fd = new FormData();
            fd.append("nama", this.nama);
            fd.append("tahun", this.tahun);
            fd.append("anggaran", this.selectedanggaran);
            fd.append("uptd", this.paramselecteduptd);
            fd.append("createdby", JSON.parse(localStorage.getItem("usistem")));

            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation_program-AddProgram", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.fire('Informasi', 'Data telah tersimpan..', 'success').then(function () {
                            window.location.replace("/Program");
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
        },
        update() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }
            var fd = new FormData();
            fd.append("nama", this.nama);
            fd.append("tahun", this.tahun);
            fd.append("anggaran", this.selectedanggaran);
            fd.append("uptd", this.paramselecteduptd);
            fd.append("updatedby", JSON.parse(localStorage.getItem("usistem")));
            fd.append("id", this.secretencData);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation_program-UpdateProgram", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.fire('Informasi', 'Data telah terupdate..', 'success').then(function () {
                            window.location.replace("/Program");
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
            return false;
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.initialize();
        if (this.secretencData == 'Tambah') {
            this.ambilreff();
        }
        if (this.secretencData != 'Tambah') {
            this.AmbilDataSingel();
        }
    }
}
</script>
<style></style>