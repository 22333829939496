import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Beranda',
    component: () => import('../../components/views/Publik/Beranda.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../../components/views/Publik/Login.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/KontakKami',
    name: 'KontakKami',
    component: () => import('../../components/views/Publik/KontakKami.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DaftarRegulasi',
    name: 'DaftarRegulasi',
    component: () => import('../../components/views/Publik/DaftarRegulasi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DaftarPasar',
    name: 'DaftarPasar',
    component: () => import('../../components/views/Publik/DaftarPasar.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/PantauanPasar',
    name: 'PantauanPasar',
    component: () => import('../../components/views/Publik/PantauanPasar.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/PotensiPasar',
    name: 'PotensiPasar',
    component: () => import('../../components/views/Publik/PotensiPasar.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DaftarLapak',
    name: 'DaftarLapak',
    component: () => import('../../components/views/Publik/DaftarLapak.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/GIS/:id',
    name: 'GIS',
    component: () => import('../../components/views/Publik/GIS.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: () => import('../../components/views/Dashboard/Dashboard.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Slider',
    name: 'Slider',
    component: () => import('../../components/views/Backoffice/Slider/Slider.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesSlider/:id',
    name: 'ProsesSlider',
    component: () => import('../../components/views/Backoffice/Slider/ProsesSlider.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/BahanMasuk',
    name: 'BahanMasuk',
    component: () => import('../../components/views/Backoffice/BahanMasuk/BahanMasuk.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesBahanMasuk/:id',
    name: 'ProsesBahanMasuk',
    component: () => import('../../components/views/Backoffice/BahanMasuk/ProsesBahanMasuk.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/UpdateBahanMasuk/:id',
    name: 'UpdateBahanMasuk',
    component: () => import('../../components/views/Backoffice/BahanMasuk/UpdateBahanMasuk.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/BahanKeluar',
    name: 'BahanKeluar',
    component: () => import('../../components/views/Backoffice/BahanKeluar/BahanKeluar.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/BahanPO',
    name: 'BahanPO',
    component: () => import('../../components/views/Backoffice/BahanPO/BahanPO.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesBahanPO/:id',
    name: 'ProsesBahanPO',
    component: () => import('../../components/views/Backoffice/BahanPO/ProsesBahanPO.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Regulasi',
    name: 'Regulasi',
    component: () => import('../../components/views/Backoffice/Regulasi/Regulasi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesRegulasi/:id',
    name: 'ProsesRegulasi',
    component: () => import('../../components/views/Backoffice/Regulasi/ProsesRegulasi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/InfoBeranda',
    name: 'InfoBeranda',
    component: () => import('../../components/views/Backoffice/InfoBeranda/InfoBeranda.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesInfoBeranda/:id',
    name: 'ProsesInfoBeranda',
    component: () => import('../../components/views/Backoffice/InfoBeranda/ProsesInfoBeranda.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/KategoriData',
    name: 'KategoriData',
    component: () => import('../../components/views/Backoffice/KategoriData/KategoriData.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesKategoriData/:id',
    name: 'ProsesKategoriData',
    component: () => import('../../components/views/Backoffice/KategoriData/ProsesKategoriData.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/LayerPeta',
    name: 'LayerPeta',
    component: () => import('../../components/views/Backoffice/LayerPeta/LayerPeta.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesLayerPeta/:id',
    name: 'ProsesLayerPeta',
    component: () => import('../../components/views/Backoffice/LayerPeta/ProsesLayerPeta.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/User',
    name: 'User',
    component: () => import('../../components/views/Backoffice/User/User.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesUser/:id',
    name: 'ProsesUser',
    component: () => import('../../components/views/Backoffice/User/ProsesUser.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Program',
    name: 'Program',
    component: () => import('../../components/views/Backoffice/Program/Program.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesProgram/:id',
    name: 'ProsesProgram',
    component: () => import('../../components/views/Backoffice/Program/ProsesProgram.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Kegiatan',
    name: 'Kegiatan',
    component: () => import('../../components/views/Backoffice/Kegiatan/Kegiatan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesKegiatan/:id',
    name: 'ProsesKegiatan',
    component: () => import('../../components/views/Backoffice/Kegiatan/ProsesKegiatan.vue'),
    meta: {
      transition: "zoom",
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: 'history',
  routes: routes,
  linkActiveClass: 'active',
})

export default router;
