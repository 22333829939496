<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <div class="content-wrapper py-5">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6 wow fadeInDown">
                            <h5>Pengaturan Bahan Masuk</h5>
                        </div>
                        <div class="col-sm-6 wow fadeInDown">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item"><a href="/BahanMasuk">Data Bahan Masuk</a></li>
                                <li class="breadcrumb-item active">Tambah Data</li>
                            </ol>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12 wow fadeInDown">
                            <div class="card cardputih">
                                <div class="card-header border-transparent">
                                    <h3 class="card-title">Form Input Bahan Masuk</h3>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-3">
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <label>Tanggal Masuk <small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="date" class="form-control form-control-sm"
                                                    id="tanggalmasuk" name="tanggalmasuk" placeholder="Tanggal Masuk"
                                                    v-model="tanggalmasuk">
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <label>Nomor PO </label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="nomorpo"
                                                    name="nomorpo" placeholder="Nomor PO" v-model="nomorpo">
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <label>UPTD <small class="text-info"> (*wajib
                                                    dipilih)</small></label>
                                            <v-select :options="uptd" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelecteduptd" @update:modelValue="onuptdChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-12 previous" style="background-color: #fafafa;"
                                            v-for="(koodinatsetuju, counter) in koodinatsetujus" v-bind:key="counter">
                                            <div class="row">
                                                <div class="col-sm-12 text-left">
                                                    <label>Opsi Data {{ counter + 1 }}.&nbsp;</label>
                                                    <div class="col-sm-12 text-left">
                                                        <button type="button" class="btn btn-danger btn-sm"
                                                            @click="addKoordinat"><i
                                                                class="far fa-plus-square"></i></button>
                                                        <button type="button" class="btn btn-danger btn-sm"
                                                            @click="deleteKoordinat(counter)" v-if="(counter > 0)"><i
                                                                class="far fa-minus-square"></i></button>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Jenis Barang<small class="text-info"> (*wajib
                                                            diisi)</small></label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="text" class="form-control form-control-sm"
                                                            :id="'jenisbarang' + (counter + 1)" name="jenisbarang"
                                                            placeholder="Jenis Barang"
                                                            v-model="koodinatsetuju.jenisbarang">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Volume<small class="text-info"> (*wajib
                                                            diisi)</small></label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="number" class="form-control form-control-sm"
                                                            :id="'volume' + (counter + 1)" name="volume"
                                                            placeholder="Volume" v-model="koodinatsetuju.volume">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Satuan<small class="text-info"> (*wajib
                                                            diisi)</small></label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="text" class="form-control form-control-sm"
                                                            :id="'satuan' + (counter + 1)" name="satuan"
                                                            placeholder="Satuan" v-model="koodinatsetuju.satuan">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Harga Satuan<small class="text-info"> (*wajib
                                                            diisi)</small></label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="number" class="form-control form-control-sm"
                                                            :id="'hargasatuan' + (counter + 1)" name="hargasatuan"
                                                            placeholder="Harga Satuan"
                                                            v-model="koodinatsetuju.hargasatuan">
                                                    </div>
                                                </div>
                                                <div class="col-sm-12">
                                                    <hr>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer p-3">
                                    <button type="button" class="btn btn-sm btn-success3 float-left text-white lebar2"
                                        @click="simpan()" v-if="(secretencData == 'Tambah')" data-toggle="tooltip"
                                        data-placement="left" title="Simpan data.."><i class="fas fa-plus-square"></i>
                                        Simpan</button>
                                    <button type="button" class="btn btn-sm btn-success3 float-left text-white lebar2"
                                        v-if="(secretencData != 'Tambah')" @click="update()" data-toggle="tooltip"
                                        data-placement="left" title="Update data.."><i class="fa fa-check-circle"></i>
                                        Update</button>
                                    <button type="button" class="btn btn-sm btn-danger3 float-left text-white lebar2"
                                        @click="batalproses()" data-toggle="tooltip" data-placement="left"
                                        title="Batalkan proses.."><i class="fas fa-window-close"></i> Batal</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Footer, NavBar, Menu, vSelect, swal
    },
    data() {
        return {
            halamanloading: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            organisasi: JSON.parse(localStorage.getItem("orsistem")),
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
            tanggalmasuk: '',
            nomorpo: '',
            uptd: '',
            counter: 0,
            koodinatsetujus: [
                {
                    previous: '',
                    expiration: '',
                }
            ],
            uptd: [],
            datauptd: [],
            defaultSelecteduptd: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: '',
            },
            selecteduptd: '',
            validasi: false,
        }
    },
    methods: {
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
            this.halamanloading = false;
        },
        addKoordinat() {
            this.koodinatsetujus.push({
                previous: '',
                expiration: ''
            })
        },
        deleteKoordinat(counter) {
            this.koodinatsetujus.splice(counter, 1);
        },
        ontabelChange(a) {
            try {
                this.selectedtabel = this.tabel[a].tag;
            }
            catch (err) {
                return;
            }
        },
        batalproses() {
            window.location.replace("/BahanMasuk");
        },
        async ambilreff() {
            this.halamanloading = true;
            var mainAPIDataSingel = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIDataSingel.get("parastapainnovation_reff-UPTDPengguna?random=" + random + "&organisasi=" + this.organisasi + "&level=" + this.leveluser).then(
                Response => {
                    this.datauptd = [];
                    this.uptd = [];
                    this.datauptd = Response.data.content.data;
                    this.datauptd.forEach((item) => {
                        this.uptd.push({
                            label: item.nama,
                            code: this.datauptd.indexOf(item) + 1,
                            tag: item.id,
                        });
                    });
                    this.uptd.unshift({
                        label: 'Semua Data',
                        code: 0,
                        tag: '',
                    });
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        onuptdChange(a) {
            try {
                this.selecteduptd = this.uptd[a].tag;
            }
            catch (err) {
                return;
            }
        },
        validasidata() {
            if (document.getElementById('tanggalmasuk').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('tanggalmasuk').focus();
                return false;
            }
            if ((this.selecteduptd == '') || (this.selecteduptd == null) || (this.selecteduptd == undefined)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Pilih Data',
                });
                return false;
            }
            for (let i = 1; i <= this.koodinatsetujus.length; i++) {
                if (document.getElementById('jenisbarang' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('jenisbarang' + i).focus();
                    return false;
                }
                if (document.getElementById('volume' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('volume' + i).focus();
                    return false;
                }
                if (document.getElementById('satuan' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('satuan' + i).focus();
                    return false;
                }
                if (document.getElementById('hargasatuan' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('hargasatuan' + i).focus();
                    return false;
                }

            }
            this.validasi = true;
            return;
        },
        simpan() {
            //alert(this.koodinatsetujus.length);
            //return false;
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }
            swal.fire({
                title: "Konfirmasi",
                text: "Yakin Data Sudah Benar Dan Hendak Menyimpan Data?",
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: "Batal",
                confirmButtonColor: "#DC143C",
                cancelButtonColor: "#ffaa05",
                confirmButtonText: "Simpan"
            }).then((result) => {
                if (result.isConfirmed) {

                    var fd = new FormData();
                    var jenisbarang = "";
                    var volume = "";
                    var satuan = "";
                    var hargasatuan = "";
                    var jumlahharga = "";
                    for (let i = 1; i <= this.koodinatsetujus.length; i++) {
                        if (jenisbarang == "") {
                            jenisbarang = jenisbarang + document.getElementById("jenisbarang" + i).value;
                        }
                        else {
                            jenisbarang = jenisbarang + "|" + document.getElementById("jenisbarang" + i).value;
                        }
                        if (volume == "") {
                            volume = volume + document.getElementById("volume" + i).value;
                        }
                        else {
                            volume = volume + "|" + document.getElementById("volume" + i).value;
                        }

                        if (satuan == "") {
                            satuan = satuan + document.getElementById("satuan" + i).value;
                        }
                        else {
                            satuan = satuan + "|" + document.getElementById("satuan" + i).value;
                        }
                        if (hargasatuan == "") {
                            hargasatuan = hargasatuan + document.getElementById("hargasatuan" + i).value;
                        }
                        else {
                            hargasatuan = hargasatuan + "|" + document.getElementById("hargasatuan" + i).value;
                        }
                        if (jumlahharga == "") {
                            jumlahharga = jumlahharga + (document.getElementById("hargasatuan" + i).value * document.getElementById("volume" + i).value);
                        }
                        else {
                            jumlahharga = jumlahharga + "|" + (document.getElementById("hargasatuan" + i).value * document.getElementById("volume" + i).value);
                        }

                    }
                    fd.append("tanggalmasuk", this.tanggalmasuk);
                    fd.append("nomorpo", this.nomorpo);
                    fd.append("uptd", this.selecteduptd);
                    fd.append("jenisbarang", jenisbarang);
                    fd.append("volume", volume);
                    fd.append("satuan", satuan);
                    fd.append("hargasatuan", hargasatuan);
                    fd.append("jumlahharga", jumlahharga);
                    fd.append("createdby", JSON.parse(localStorage.getItem("usistem")));

                    const mainAPI = axios.create({
                        baseURL: process.env.VUE_APP_URL_API,
                        headers: {
                            "Content-Type": "form-data",
                            Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                        },
                    });
                    mainAPI.post("parastapainnovation_barang-AddBarangMasuk", fd).then(
                        Response => {
                            if (Response.data.response == 'error') {
                                swal.fire('Peringatan', Response.data.message, 'error');
                            }
                            else {
                                swal.fire('Informasi', 'Data telah tersimpan..', 'success').then(function () {
                                    window.location.replace("/BahanMasuk");
                                });
                            }
                        }
                    ).catch(function (error) {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: error
                        });
                    }
                    )
                }
            });
            return false;
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.ambilreff();
        if (this.secretencData != 'Tambah') {
            this.AmbilDataSingel();
        }
    }
}
</script>
<style></style>